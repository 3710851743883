import { Badge, Box, Container,  Img, Stack, } from '@chakra-ui/react'

export const HeroWithCropedImage = ({ title = "", options, badge = "", image = [], actions = {}, ...props }) => (
  <Box
    position="relative"
    height={{
      lg: '100vh',
    }}
  >
    <Container
      py={{
        base: '16',
        md: '24',
      }}
      height="full"
    >
      <Stack
        direction={{
          base: 'column',
          lg: 'row',
        }}
        spacing={{
          base: '16',
        }}
        align={{
          lg: 'center',
        }}
        height="full"
      >
        <Stack
          spacing={{
            base: '8',
            md: '12',
          }}
        >
          <Stack spacing="4">
            {!!badge && <Badge
              variant="pill"
              colorScheme="blue"
              alignSelf="start"
              size={{
                base: 'md',
                md: 'lg',
              }}
            >
              {badge}
            </Badge>}
            <Stack
              spacing={{
                base: '4',
                md: '6',
              }}
              maxW={{
                md: 'xl',
                lg: 'md',
                xl: 'xl',
              }}
            >
              
                {title}
    
              {options}
            </Stack>
          </Stack>
          <Stack
            direction={{
              base: 'column',
              md: 'row',
            }}
            spacing="3"
          >
            {actions}
          </Stack>
        </Stack>
        <Box
          pos={{
            lg: 'absolute',
          }}
          right="0"
          bottom="0"
          w={{
           
            lg: '50%',
          }}
          height={{
         
            lg: 'full',
          }}
          sx={{
            clipPath: {
              lg: 'polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)',
            },
          }}
        >
          <Img
            boxSize="full"
            objectFit="cover"
            src={image}
            alt="Support Image"
          />
        </Box>
      </Stack>
    
    </Container>
  </Box>
)
