// 1. Import the default chakra ui PRo Theme
import { theme } from '@chakra-ui/pro-theme'
import { gmfHealthCareTheme } from './theme/gmfHealthCareTheme'
// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"


// 2. Call `extendTheme` and pass your custom values

const proTheme = extendTheme(theme)
console.log(proTheme.components.Heading)
const extenstion = {
  components: {
    ...proTheme.components,
    Heading: {
      ...proTheme.components.Heading,
      ...gmfHealthCareTheme.components.Heading
    }
  },

  fonts: {
    ...proTheme.fonts,
    ...gmfHealthCareTheme.fonts
  },
  colors: {
    ...proTheme.colors, brand: gmfHealthCareTheme.brand.colors.secondary
  },
}
const myTheme = extendTheme(extenstion, proTheme)


export default myTheme;