



import {HomePage} from '@pages/HomePage';
import { SupportPage } from '@pages/SupportPage';



export const publicRoutes = [
    { path: '/', element: <HomePage />, public_route: true, breadcrumb: 'Home' },
    { path: '/support', element: <SupportPage />, public_route: true, breadcrumb: 'Support' },
   

];
/*export const privateRoutes = [
    {
        path: '/dashboard', element: <StatIcon />, breadcrumb: 'Dashbord'
    },
]*/
