


export const gmfHealthCareTheme={


    components: {
        Heading: {
            baseStyle: (props) => ({
                color: props?.colorMode === 'dark' ? 'white' : '#52489C',
                _hover: {
                    textDecoration: "none",
                },
                "&[data-highlight]": {
                    bg: '#fef4e8',
                    borderRadius: 'md',
                },
            }),
        }
    },
    fonts: {
        body: "system-ui, sans-serif",
        heading: "Roboto Slab, serif",
        mono: "Menlo, monospace",
    },

    brand: {
        colors: {
            primary://Primary Brand Color
            {

                50: '#d8faff',
                100: '#acf0ff',
                200: '#7de9ff',
                300: '#4de6ff',
                400: '#27d0fe',
                500: '#16aae5',
                600: '#0076b3',
                700: '#004c81',
                800: '#00284f',
                900: '#000c1e',
            },
            secondary://Secondary Brand Color


            {
                50: '#ddf3ff',
                100: '#b0d7ff',
                200: '#81bcfd',
                300: '#51a2f9',
                400: '#2287f6',
                500: '#096edd',
                600: '#0055ad',
                700: '#003d7d',
                800: '#00254e',
                900: '#000d20',
            },
            purple:
            {
                50: '#f8e4ff',
                100: '#dfb4ff',
                200: '#c383fc',
                300: '#a553f9',
                400: '#8422f6',
                500: '#6409dd',
                600: '#5906ad',
                700: '#47037d',
                800: '#30014d',
                900: '#15001f',
            },
            ocean:
            {
                50: '#dcf3ff',
                100: '#b4dcfb',
                200: '#8ac7f3',
                300: '#5eb5ec',
                400: '#33a7e5',
                500: '#1a81cc',
                600: '#0c5a9f',
                700: '#013773',
                800: '#001c48',
                900: '#00081e',
            }

        }
    }
}
