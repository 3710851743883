

import { Box, Heading } from "@chakra-ui/layout"
import { HeroWithCropedImage } from "@components/heroes/HeroWithCropedImage"
import { Button, Container, FormControl, FormLabel, HStack, Icon, Text } from '@chakra-ui/react'
import { FiSun } from 'react-icons/fi'
import { CustomSelect } from '@components/customSelect/CustomSelect'
import { Option } from "@components/customSelect/Option" 



const title = (<Heading
    size={{
        base: 'md',
        md: 'xl',

    }}
    
>
    We are here for you!
</Heading>)

const helpOptions = [
    {
        title: 'Health Insurance',
        url: '#',
        icon: FiSun
    },
    {
        title: 'Medicare',
        url: '#',
        icon: FiSun
    },
    {
        title: 'Insurance',
        url: '#'
    },
    {
        title: 'Dental +',
        url: '#'
    },
    {
        title: 'Vision +',
        url: '#'
    }
]

const formOptions = (<Box
    as="section"

    pt={{
        base: '4',
        md: '8',
    }}
    pb="40"
>
    <Container maxW="lg">
        <FormControl id="colormode">
            <FormLabel fontSize={'medium'}>What would you like help with today?</FormLabel>
            <CustomSelect
                name="ColorMode"
                colorScheme="blue"
                placeholder="Find your issue">
                {helpOptions.map(option => <Option key={option?.title} value={option?.url}>
                    <HStack>
                        <Icon as={option?.icon} />
                        <Text>{option.title}</Text>
                    </HStack>
                </Option>)}
            </CustomSelect>
        </FormControl>
    </Container>
</Box>)


export const SupportPage = () => {
    return (<Box position={'relative'}>
        <HeroWithCropedImage
            actions={<><Button
                size={{
                    base: 'lg',
                    md: 'xl',
                }}
            >
                Create a tickect
            </Button>
                <Button
                    variant="secondary"
                    size={{
                        base: 'lg',
                        md: 'xl',
                    }}
                >
                    Learn more
                </Button></>}
            options={formOptions}
            title={title}
            image={'https://res.cloudinary.com/druyvgiba/image/upload/v1705516222/Corobiz/AdobeStock_592218028_hwdxt6.png'}
        />

    </Box>
    )
}


