
import { Box } from '@chakra-ui/react';
import { Router } from './router/Router';


function App() {
  return (
    <Box>
      <Router />
    </Box>
  );
}

export default App;
