import { Route, Routes, } from 'react-router-dom';

import { v4 as uuid } from 'uuid';
import { publicRoutes } from './Routes';
import { Box } from '@chakra-ui/react';


export const routes = [
  ...publicRoutes,


];

export const Router = () => {
  
  return (
    <Routes>
      {routes.map(({ path, element, public_route = false, exact = false }) => {
          return (<Route key={uuid()} path={path} element={element} exact={exact} />)
      })}


      <Route path="*" element={<Box>404</Box>} />

    </Routes>
  )

}
